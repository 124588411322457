import React, { createContext, useContext, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import usePortal from 'react-useportal';
import * as styles from './NotificationsContext.module.scss';

interface NotificationValue {
  showNotification: (message: React.ReactElement) => void;
}

// We assume this NotificationsContext will never be used unless inside
// the NotificationsContext.Provider, and so the default is never used.
const NotificationsContext = createContext<NotificationValue>({} as NotificationValue);

export const useNotifications = () => useContext(NotificationsContext);

interface NotificationsProviderProps {
  children: React.ReactNode;
}

export function NotificationsProvider({ children }: NotificationsProviderProps) {
  const [notificationOptions, setNotificationOptions] = useState<{
    message: React.ReactElement;
  } | null>(null);

  function showNotification(message: React.ReactElement) {
    setNotificationOptions({ message });

    setTimeout(() => {
      setNotificationOptions(null);
    }, 8000);
  }

  const { Portal } = usePortal();

  return (
    <NotificationsContext.Provider value={{ showNotification }}>
      {notificationOptions && (
        <Portal>
          <div className={styles.notificationContainer}>
            <div className={styles.notification}>
              <FaCheckCircle className={styles.notificationIcon} />
              <p>{notificationOptions.message}</p>
            </div>
          </div>
        </Portal>
      )}
      {children}
    </NotificationsContext.Provider>
  );
}
